<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :span="12">
            <a-form-item :label="'产品类型'">
              <a-select default-value="" style="width: 190px" v-model="queryParam.appSsid">
                <a-select-option value=""> 全部 </a-select-option>
                <a-select-option value="01"> NovaCash-01 </a-select-option>
                <a-select-option value="02"> ListoCash-02 </a-select-option>
                <a-select-option value="03"> MoniMoni-03 </a-select-option>
                <a-select-option value="04"> FusMoney-04 </a-select-option>
                <a-select-option value="05"> PesoPréstamos-05 </a-select-option>
                <a-select-option value="06"> OkCrédito-06 </a-select-option>
                <a-select-option value="07"> OkPréstamos-07 </a-select-option>
                <a-select-option value="09"> goncredito-09 </a-select-option>
                <a-select-option value="10"> Préstamos Tala-10 </a-select-option>
                <a-select-option value="11"> goncredito-11 </a-select-option>
                <a-select-option value="12"> EnviarDinero-12 </a-select-option>
                <a-select-option value="13"> 自己贷超-13 </a-select-option>
                <a-select-option value="14"> mobi-14 </a-select-option>
                <a-select-option value="15"> avalcred-15 </a-select-option>
                <a-select-option value="16"> Créditos Celular-16 </a-select-option>
                <a-select-option value="17"> PesoFábrica-17 </a-select-option>
                <a-select-option value="18"> ListoFectivo-18 </a-select-option>
                <a-select-option value="19"> SuperEfectivo-19 </a-select-option>
                <a-select-option value="20"> TasaPréstamos-20 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="8">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <div class="table-operator" style="border-top: 5px">
        <a-button @click="handleAdd" type="primary" icon="plus">添加</a-button>
      </div>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
        <span slot="statusType" slot-scope="text" :value="text">{{ $t(STATUS_TYPE[text]) }}</span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)"> 编辑 </a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </div>
    <AppSettingModal ref="modalForm" @ok="modalFormOk"></AppSettingModal>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getAppSettingList, deleteAppSetting } from '@/api/system'
import Dict from '../modules/Dict'
import { STATUS_TYPE } from '../../constants/status'
import AppSettingModal from './modules/AppSettingModal'
export default {
  name: 'AppList',
  components: {
    AppSettingModal,
    Dict,
  },
  data() {
    return {
      visible: false,
      STATUS_TYPE,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      dataSource: [],
      // 查询条件
      queryParam: {
        appSsid: '',
      },
      loading: false,
      // 表头
      columns: [
        {
          title: 'APPSSID',
          align: 'left',
          dataIndex: 'appSsid',
        },
        {
          title: '应用名称',
          align: 'left',
          dataIndex: 'appName',
        },
        {
          title: '首贷是否拒绝',
          align: 'left',
          dataIndex: 'firstApprovalM',
          scopedSlots: { customRender: 'statusType' },
        },
        {
          title: '复贷是否拒绝',
          align: 'left',
          dataIndex: 'reApprovalM',
          scopedSlots: { customRender: 'statusType' },
        },
        {
          title: '是否拒绝',
          align: 'left',
          dataIndex: 'allApprovalM',
          scopedSlots: { customRender: 'statusType' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      isorter: {
        column: 'createTime',
        order: 'desc',
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 },
      },
    }
  },
  created() {
    this.loadData(1)
  },
  methods: {
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams() // 查询条件
      getAppSettingList(Object.assign({}, params))
        .then((res) => {
          if (res.code === 2000) {
            this.ipagination.total = res.data.total
            this.dataSource = res.data.rows
          } else {
            this.ipagination.total = 0
            this.dataSource = []
            this.$message.error(res.msg)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    getQueryParams() {
      var param = Object.assign({}, this.queryParam, this.isorter)
      param.pageNum = this.ipagination.current
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },
    handleTableChange(pagination, filters, sorter) {
      // TODO 筛选
      // if (Object.keys(sorter).length > 0) {
      //   this.isorter.column = sorter.field
      //   this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      // }
      this.ipagination = pagination
      this.loadData()
    },
    handleAdd() {
      this.$refs.modalForm.add()
      this.$refs.modalForm.title = '新增'
    },
    handleEdit(record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
    },
    handleDelete(id) {
      deleteAppSetting({ id }).then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.loadData()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.appSsid = ''
      that.loadData(this.ipagination.current)
    },
    // model回调
    modalFormOk() {
      this.loadData()
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/common.less';
</style>
