import { axios } from '@/utils/request'

// 文件查询
export function getDocumentList(data = {}) {
    return axios({
      url: 'payout/excel/queryFilePage',
      method: 'post',
      data
    })
  }

  // 下载Excel
  export function getPayoutExcel( data ) {
    return axios({
      url: `/payout/excel/downloadPayoutExcel`,
      // url: `/payout/excel/downloadFile`,
      method: 'post',
      maxBodyLength: Infinity,
      responseType: 'blob',
      data
    })
  }

  // 上传文件
  export function postPayoutExcel(data = {}) {
    return axios({
      url: 'payout/excel/uploadPayoutCallBackExcel',
      method: 'post',
      data
    })
  }

  export function getDownloadloan( data ) {
    return axios({
      url: `/payout/excel/downloadFile`,
      method: 'post',
      maxBodyLength: Infinity,
      responseType: 'blob',
      data
    })
  }

