<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
      <a-row>
        <a-col :span="6">
          <a-form-item label="商户编号" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-input style="width: 150px" v-model="queryParams.userNo" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="商户名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-input style="width: 150px" v-model="queryParams.userName" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="交易类型" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-select style="width: 150px" v-model="queryParams.bizType" allowClear>
              <a-select-option value="1">放款规则</a-select-option>
              <a-select-option value="2">还款规则</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="规则类型" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-select style="width: 150px" @change="handleChange" allowClear>
              <a-select-option v-for="item in ruleTemplate" :value="item.itemValue" :key="item.itemValue"
                >{{ item.itemText }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
            <a-button type="primary" @click="() => {queryParams.pageNum = 1; searchQuery()}" icon="search">查询</a-button>
            <a-button type="primary" style="margin-left: 8px" @click="createRule">新增</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :scroll="{ x: 1000 }"
      :pagination="false"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)"> 编辑 </a>
        <a-divider type="vertical" />
        <a :disabled="record.status == 1" @click="onRuleStatusUpdate(record, 1)">启用</a>
        <a-divider type="vertical" />
        <a :disabled="record.status == 2" @click="onRuleStatusUpdate(record, 2)">禁用</a>
      </span>
      <span slot="ruleCode" slot-scope="text, record">
        {{ getRuleName(record.ruleCode) }}
      </span>
      <span slot="bizType" slot-scope="text, record">
        <a-tooltip>
          <template slot="title"> prompt text </template>
          {{ record.bizType == 1 ? '放款规则' : '还款规则' }}
        </a-tooltip>
      </span>
      <span slot="status" slot-scope="text, record">
        {{ record.status == 1 ? '启用' : record.status == 2 ? '禁用' : '无状态' }}
      </span>
      <span slot="createTime" slot-scope="text, record">
        {{ moment(record.createTime).format('YYYY-MM-DD HH:mm:SS') }}
      </span>
      <span slot="coditionParam" slot-scope="text, record">
        <span v-if="record.ruleCode == 1000">{{ fomartCondition(record) }}</span>
        <a-tooltip placement="topLeft" v-else>
          <template slot="title"> [最小订单 - 最大订单, 费率] </template>
          <div v-for="(item, index) in JSON.parse(record.conditionParam).stepList" :key="index">
            {{ `[ ${item.minNum} - ${item.maxNum}, ${item.rate}] ` }}
          </div>
        </a-tooltip>
      </span>
      >
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="queryParams.pageNum"
        :page-size="queryParams.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
    <a-modal :visible="modalvisible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <a-form :form="form2">
        <a-form-item label="商户编号">
          <a-select
            ref="select"
            style="width: 120px"
            @change="onUserNameChange"
            v-decorator="['userId', { rules: [{ required: true, message: '请选择商户编号!' }] }]"
          >
            <a-select-option v-for="item in merchants" :value="item.userId" :key="item.userNo"
              >{{ item.userName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="规则类型">
          <a-select
            ref="select"
            style="width: 120px"
            @change="onRuleCodeChange"
            v-decorator="[
              'ruleCode',
              { rules: [{ required: true, message: '请选择规则类型!' }] }, // , initialValue: '1002'
            ]"
          >
            <a-select-option v-for="item in ruleTemplate" :value="item.itemValue" :key="item.itemValue"
              >{{ item.itemText }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="交易类型" v-show="!edit">
          <a-select
            ref="select"
            style="width: 120px"
            v-decorator="['bizType', { rules: [{ required: true, message: '请选择业务类型!' }], initialValue: '1' }]"
          >
            <a-select-option value="1">放款规则</a-select-option>
            <a-select-option value="2">还款规则</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="固定金额" v-if="selectRuleType == 'deduction'">
          <a-input
            type="number"
            min="0"
            v-decorator="['fixedAmount', { rules: [{ required: true, message: '请输入固定金额!' }] }]"
          />
        </a-form-item>
        <div v-if="selectRuleType == 'stepList'">
          <a-card
            v-for="(item, index) in stepList"
            :key="index"
            :title="'阶梯' + (index + 1)"
            style="margin-top: 5px; margin-bottom: 5px"
          >
            <a slot="extra" @click="removeAStep(index)">删除</a>
            <a-form-item label="最小订单数">
              <a-input
                @change="onMinNumChange"
                type="number"
                min="0"
                :data-index="index"
                v-decorator="[
                  'minNum' + index,
                  { rules: [{ required: true, message: '请输入最小订单数!' }], initialValue: item.minNum },
                ]"
              />
            </a-form-item>
            <a-form-item label="最大订单数">
              <a-input
                @change="onMaxNumChange"
                type="number"
                min="0"
                :data-index="index"
                v-decorator="[
                  'maxNum' + index,
                  { rules: [{ required: true, message: '请输入最大订单数!' }], initialValue: item.maxNum },
                ]"
              />
            </a-form-item>
            <a-form-item label="费率">
              <a-input
                @change="onRatechange"
                type="number"
                min="0"
                :data-index="index"
                v-decorator="[
                  'rate' + index,
                  { rules: [{ required: true, message: '请输入费率!' }], initialValue: item.rate },
                ]"
              />
            </a-form-item>
          </a-card>
          <a-button type="primary" @click="addAStep">添加</a-button>
        </div>
        <a-form-item label="费率" v-if="selectRuleType == 'deduction'">
          <a-input
            type="number"
            min="0"
            v-decorator="['rate', { rules: [{ required: true, message: '请输入费率!' }] }]"
          />
        </a-form-item>
        <a-form-item label="结果">
          <p v-if="validResult" style="color: blue">{{ validResult }}</p>
        </a-form-item>
      </a-form>
      <div slot="footer">
        <a-button type="primary" @click="handleValid">校验</a-button>
        <a-button type="primary" @click="handleOk">提交</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { updateUserRule, getUserRuleList, saveUserRule, checkUserRule, updateUserRuleStatus } from '@/api/useRule'
import { findDictItemByCode, getMerchantPage } from '@/api/pay'
import { message } from 'ant-design-vue/es'
import ATip from 'ant-design-vue/es/tooltip'
import moment from 'moment'

export default {
  name: 'UserRule',
  components: {
    ATip,
  },
  data() {
    return {
      moment,
      queryParams: {
        ruleName: '', // 规则名称
        ruleCode: '',
        userNo: '',
        userName: '',
        bizType: '',
        pageNum: 1,
        pageSize: 10,
      },
      dataSource: [],
      ruleTemplate: [],
      merchants: [],
      loading: false,
      total: 0,
      columns: [
        {
          title: '创建日期',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '商户编号',
          dataIndex: 'userNo',
        },
        {
          title: '商户名称',
          dataIndex: 'userName',
        },
        {
          title: '规则类型',
          dataIndex: 'bizType',
          scopedSlots: { customRender: 'bizType' },
        },
        {
          title: '规则类型',
          dataIndex: 'ruleCode',
          scopedSlots: { customRender: 'ruleCode' },
        },
        {
          width: 180,
          title: '按类型显示名称',
          dataIndex: 'conditionParam',
          scopedSlots: { customRender: 'coditionParam' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          width: 180,
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      edituserId: '',
      confirmLoading: false,
      modalvisible: false,
      edit: false,
      editRuleId: '',
      modalForm: {
        ruleCode: '',
        ruleName: '',
        webRule: {},
      },
      form2: this.$form.createForm(this, { name: 'coordinated' }),
      selectRuleType: '',
      stepList: [{}],
      userName: localStorage.getItem('userName') || '',
      validResult: '',
    }
  },
  created() {
    findDictItemByCode('ruleTemplate').then((res) => {
      this.ruleTemplate = res.data.ruletemplate
    })

    getMerchantPage({ pageNum: 1, pageSize: 999 }).then((res) => {
      this.merchants = res.data.list
    })
  },
  computed: {
    ...mapGetters(['userNo', 'userId']),
  },
  methods: {
    getRuleName(ruleCode) {
      const res = this.ruleTemplate.find((item) => item.itemValue == ruleCode)
      return res ? res.itemText : ''
    },
    addAStep() {
      this.stepList.push({})
    },
    removeAStep(index) {
      if (this.stepList.length === 1) {
        return message.error('留一条数据')
      }
      this.stepList.splice(index, 1)
    },
    onRatechange(e) {
      const index = e.target.dataset.index
      const val = e.target.value
      this.stepList[index]['rate'] = +val
    },
    onMinNumChange(e) {
      const index = e.target.dataset.index
      const val = e.target.value
      this.stepList[index]['minNum'] = +val
    },
    onMaxNumChange(e) {
      const index = e.target.dataset.index
      const val = e.target.value
      this.stepList[index]['maxNum'] = +val
    },
    fomartCondition(record) {
      const conditions = JSON.parse(record.conditionParam || '{}')
      const { deduction } = conditions
      // 坐扣
      if (deduction) {
        return `固定金额: ${deduction.fixedAmount}, 费率: ${deduction.rate}`
      }
    },
    onRuleCodeChange(ruleCode) {
      console.log('cocdddd' + ruleCode)
      const res = this.ruleTemplate.find((item) => item.itemValue == ruleCode)

      // this.selectRuleType = ruleCode
      this.selectRuleType = res ? res.itemTextEn : ''
      this.form2.setFieldsValue({
        ruleCode,
      })
    },
    onUserNameChange(userId) {
      this.form2.setFieldsValue({ userId })
    },
    onExpressChange(express) {
      this.form2.setFieldsValue({ express: express.target.value })
    },
    handleChange(value) {
      this.queryParams.ruleCode = value
    },
    handleTableChange(pageNum, pageSize) {
      this.queryParams.pageNum = pageNum
      this.queryParams.pageSize = pageSize
      this.searchQuery()
    },
    onRuleStatusUpdate(record, status) {
      updateUserRuleStatus({...record, status}).then((res) => {
        if (res.code === '2000') {
          message.success('更新成功')
          return this.searchQuery()
        } else {
          message.error(res.message)
        }
      }).catch((err) => { 

      })
    },
    handleEdit(row) {
      const ruleCode = row.ruleCode
      const res = this.ruleTemplate.find((item) => item.itemValue == ruleCode)
      this.selectRuleType = res ? res.itemTextEn : ''
      const json = JSON.parse(row.conditionParam || '{}')
      let params = {}

      if (this.selectRuleType == 'deduction') {
        if (json.deduction) {
          params = {
            ruleCode,
            userId: row.userId,
            fixedAmount: json.deduction.fixedAmount,
            rate: json.deduction.rate,
          }
        }
      } else {
        if (json.stepList) {
          this.stepList = json.stepList
        }
        params = {
          ruleCode,
          userId: row.userId,
        }
      }

      this.edit = true
      this.editRuleId = row.id
      this.modalvisible = true

      this.$nextTick(() => {
        this.form2.setFieldsValue({
          ...params,
        })
      })

      // this.modalvisible = true
      // this.edit = true
      // this.editRuleId = row.id
    },
    createRule() {
      this.modalvisible = true
    },
    async handleOk() {
      this.confirmLoading = true
      if (this.edit) {
        const fields = this.form2.getFieldsValue()
        let data = {
          bizType: +fields['bizType'],
          ruleCode: fields['ruleCode'],
          userId: fields['userId'],
          id: this.editRuleId,
          creator: this.userName,
          updater: this.userName,
        }

        const webRule = {}
        if (this.selectRuleType == 'deduction') {
          webRule.webDeduction = {
            fixedAmount: +fields['fixedAmount'],
            rate: +fields['rate'],
          }
        } else {
          webRule.webStepList = this.stepList
        }
        data.webRule = webRule

        console.log(data)
        updateUserRule(data)
          .then((res) => {
            this.confirmLoading = false
            if (res.code == '2000') {
              this.modalvisible = false
              this.edit = false
              message.success('编辑成功')
              this.searchQuery()
              this.handleCancel()
            } else {
              message.error(res.msg)
            }
          })
          .catch((err) => {
            this.edit = false
          })
      } else {
        this.form2.validateFields((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values)

            const fields = this.form2.getFieldsValue()
            const ruleCode = fields['ruleCode']
            const bizType = fields['bizType']

            const webRule = {}
            if (this.selectRuleType == 'deduction') {
              webRule.webDeduction = {
                fixedAmount: fields['fixedAmount'],
                rate: fields['rate'],
              }
            } else {
              webRule.webStepList = this.stepList
            }

            saveUserRule({
              userId: this.userId,
              bizType,
              userId: fields['userId'],
              ruleCode,
              webRule,
              status: 1,
              creator: this.userName,
              updater: this.userName,
            })
              .then((res) => {
                this.confirmLoading = false
                if (res.code == '2000') {
                  this.modalvisible = false
                  message.success('保存成功')
                  this.searchQuery()
                  this.handleCancel()
                }
              })
              .catch((err) => {
                this.confirmLoading = false
              })
            this.edit = false
          }
        })
      }
    },
    async handleValid() {
      this.form2.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)

          const fields = this.form2.getFieldsValue()
          const ruleCode = fields['ruleCode']
          const bizType = fields['bizType']

          const webRule = {}
          if (this.selectRuleType == 'deduction') {
            webRule.webDeduction = {
              fixedAmount: fields['fixedAmount'],
              rate: fields['rate'],
            }
          } else {
            webRule.webStepList = this.stepList
          }

          checkUserRule({
            userId: this.userId,
            bizType,
            ruleCode,
            webRule,
          })
            .then((res) => {
              this.confirmLoading = false
              if (res.code == '2000') {
                // TODO: show result
                this.validResult = res.data
              }
            })
            .catch((err) => {
              this.confirmLoading = false
            })
          this.edit = false
        }
      })
    },
    async handleCancel() {
      this.stepList = [{}]
      this.form2.setFieldsValue({
        ruleCode: '1000',
        fixedAmount: '',
        rate: '',
      })
      this.selectRuleType = 'deduction'
      this.validResult = ''
      this.modalvisible = false
      this.edit = false
    },
    searchQuery() {
      this.loading = true
      getUserRuleList(this.queryParams).then((res) => {
        this.dataSource = res.data.list
        this.total = res.data.page.total
        this.confirmLoading = false
        this.loading = false
      })
    },
  },
}
</script>
<style scoped lang="less">
.appContainer ::v-deep {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
/deep/.ant-form-item {
  margin-bottom: 14px;
}
</style>
