<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
      <a-row>
        <a-col :span="8">
          <a-form-item label="创建时间"  :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-range-picker v-model="queryParam.createTime" style="width: 230px" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="成功时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-range-picker v-model="queryParam.successTime" style="width: 240px"></a-range-picker>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="渠道流水号" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-input placeholder="请输入渠道流水号" v-model="queryParam.bankFlowNo" style="width: 220px"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8" >
          <a-form-item label="365银行订单号" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-input placeholder="365银行订单号" v-model="queryParam.bankOrderNo" style="width: 230px"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="代收状态:" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-select v-model="queryParam.status" style="width: 230px">
              <a-select-option v-for="item in status" :key="item.itemValue" :value="item.itemValue">
                {{ item.itemText }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="渠道:" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-select style="width: 230px" allowClear v-model="queryParam.channel">
              <a-select-option :value="item.channel" v-for="(item, key) in channels" :key="key"
                >{{ item.channel }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
            <a-button type="primary" style="margin-left: 10px" @click="() => {queryParam.pageNum = 1; searchQuery()}" icon="search">查询</a-button>
            <a-button type="primary" style="margin-left: 10px" @click="reset">重置</a-button>
            <a-button type="primary" style="margin-left: 10px" @click="onExport">导出</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :scroll="{ x: 1000 }"
      :pagination="false"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a
          style="
            border-radius: 14px;
            height: 28px;
            border: 1px solid #8fa2f9;
            padding: 2px 6px;
            line-height: 28px;
            margin-right: 10px;
          "
          @click="handleEdit(record)"
        >
          凭证
        </a>
        <a
          @click="handleEdit2(record)"
          style="border-radius: 14px; height: 28px; border: 1px solid #8fa2f9; padding: 2px 6px; line-height: 28px"
        >
          更多
        </a>
      </span>
      >
      <span slot="status" slot-scope="text, record">
        {{ record.status == 1 ? '启动' : status == 2 ? '禁用' : '无状态' }}
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="queryParam.pageNum"
        :page-size="queryParam.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
    <a-modal
      :visible="modalvisible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      :footer="null"
      width="400px"
    >
      <div class="modalContent">
        <div class="Item">
          <div class="left" style="color: #7d96f8">状态</div>
          <div class="right">{{ pinZ.statusText }}</div>
        </div>
        <div class="Item">
          <div class="left" style="color: #a7e8c3">代付金额</div>
          <div class="right" style="color: #a7e8c3">{{ pinZ.amount }}</div>
        </div>
        <div class="Item">
          <div class="left" style="color: #a7e8c3">手续费</div>
          <div class="right" style="color: #a7e8c3">{{ pinZ.fee }}</div>
        </div>
        <div class="Item">
          <div class="left">申请时间</div>
          <div class="right">{{ pinZ.createTime }}</div>
        </div>
        <div class="Item">
          <div class="left">完成时间</div>
          <div class="right">{{ pinZ.settleTime }}</div>
        </div>
        <div class="Item">
          <div class="left" style="color: #5974f6">交易中心</div>
          <div class="right"></div>
        </div>

        <div class="Item">
          <div class="left">平台订单</div>
          <div class="right">{{ pinZ.orderNo }}</div>
        </div>
        <div class="Item">
          <div class="left">银行名称</div>
          <div class="right">{{ pinZ.bankName }}</div>
        </div>
        <div class="Item">
          <div class="left">银行编码</div>
          <div class="right">{{ pinZ.bankCode }}</div>
        </div>
        <div class="Item">
          <div class="left">收款人姓名</div>
          <div class="right">{{}}</div>
        </div>
        <div class="Item">
          <div class="left">收款账户</div>
          <div class="right">{{ pinZ.bankAccount }}</div>
        </div>
        <div class="Item">
          <div class="left">备注</div>
          <div class="right">{{ pinZ.remark }}</div>
        </div>
      </div>
    </a-modal>
    <a-modal :visible="modalvisible2" @cancel="handleCancel2" :footer="null" width="1000px">
      <div class="modalContent modalContent2">
        <div class="c1" style="margin-right: 100px">
          <div class="Item">
            <div class="left">还款码</div>
            <div class="right">{{ more.payCode }}</div>
          </div>
          <div class="Item">
            <div class="left">用户id</div>
            <div class="right">{{ more.userId }}</div>
          </div>
          <div class="Item">
            <div class="left">调用方ip</div>
            <div class="right">{{ more.ip }}</div>
          </div>
          <div class="Item">
            <div class="left">url</div>
            <div class="right">{{ more.url }}</div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { getAllChannels } from '@/api/channel'
import { exportFile } from '@/api/exportFile'
import { findDictItemByCode, getBankOrderPage, exportOrderList } from '@/api/pay.js'
import { formatPrice } from '@/utils/util'
import moment from 'moment'

var statusArr = []
export default {
  name: 'PayOutBankOrderList',
  data() {
    return {
      queryParam: {
        bankFlowNo: '', // 银行流水号
        merchantNo: '', //平台订单号
        openIdentifyNo: '', //商户订单号
        channel: '', // 渠道
        beginTime: '', //开始时间
        endTime: '', //结束时间
        createTime: null,
        successTime: null,
        status: '', //代付状态
        pageNum: 1,
        pageSize: 10,
        // 订单类型: 1、放款 2、还款
        orderType: 2,
      },
      dataSource: [],
      channels: [],
      loading: false,
      total: 0,
      columns: [
        {
          title: '创建时间',
          align: 'left',
          width: '100px',
          dataIndex: 'createTime',
          customRender(text) {
            return moment(text).format('YYYY-MM-DD HH:mm:SS')
          },
        },
        {
          title: '渠道订单号',
          align: 'left',
          width: '100px',
          dataIndex: 'bankFlowNo',
        },
        {
          title: '商户订单号',
          align: 'left',
          width: '100px',
          dataIndex: 'openIdentifyNo',
        },
        {
          title: '365商户订单号',
          align: 'left',
          width: '150px',
          dataIndex: 'orderNo',
        },
        {
          title: '365银行订单号',
          align: 'left',
          width: '150px',
          dataIndex: 'bankOrderNo',
        },
        {
          title: '渠道',
          align: 'left',
          width: '150px',
          dataIndex: 'channel',
        },
        {
          title: '子渠道',
          align: 'left',
          width: '150px',
          dataIndex: 'childChannel',
        },
        {
          title: '交易金额',
          align: 'right',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'amount',
          customRender: (text) => {
            return formatPrice(text)
          },
        },
        {
          title: '手续费',
          align: 'right',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'merchantFee',
          customRender: (text) => {
            return formatPrice(text)
          },
        },
        {
          title: '银行返回金额',
          align: 'left',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'bankAmount',
        },
        {
          title: '渠道成本',
          align: 'left',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'bankFee',
        },
        {
          title: '代收状态',
          align: 'left',
          width: '100px',
          ellipsis: 'auto',
          dataIndex: 'status',
          //   /**
          //  * 状态 1未处理2处理中3成功4失败
          customRender: (text) => {
            let index = 0
            index = statusArr.findIndex((item) => {
              return text.toString() == item.itemValue
            })
            return statusArr[index].itemText
          },
        },
        {
          title: '完成时间',
          align: 'left',
          width: '100px',
          dataIndex: 'updateTime',
          customRender(text) {
            return moment(text).format('YYYY-MM-DD HH:mm:SS')
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'left',
          width: '150px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      edituserId: '',
      status: [],
      confirmLoading: false,
      modalvisible: false,
      modalvisible2: false,
      edit: false,
      editUserNo: '',
      pinZ: {},
      more: {},
    }
  },
  created() {
    findDictItemByCode('bankOrderStatus').then((res) => {
      this.status = res.data.bankorderstatus
      statusArr = res.data.bankorderstatus
    })

    getAllChannels().then((res) => {
      if (res.code === '2000') {
        this.channels = res.data
      }
    })
    this.queryParam.successTime = [moment().subtract(7, 'days'), moment().add(0, 'days')]
  },
  methods: {
    onExport() {
      this.loading = true
      const params = { ...this.queryParam }
      delete params.pageNum
      delete params.pageSize

      let timeStr = ''
      const { createTime, successTime } = params

      if (params.createTime) {
        params.beginTime = `${moment(params.createTime[0]).format('YYYY-MM-DD')} 00:00:00`
        params.endTime = `${moment(params.createTime[1]).format('YYYY-MM-DD')} 23:59:59`
        timeStr = params.beginTime + '~' + params.endTime 
        delete params.createTime
      }
      if (params.successTime) {
        params.beginFinishTime = moment(successTime[0]).format('YYYY-MM-DD 00:00:00')
        params.endFinishTime = moment(successTime[1]).format('YYYY-MM-DD 23:59:59')
        delete params.successTime
      }

      exportOrderList(params)
        .then((res) => {
          if (res && res.size > 0) {
            exportFile(res, '上游代收订单' + timeStr)
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    reset() {
      const ignoreFields = ['pageNum', 'pageSize', 'orderType']
      for (let key in this.queryParam) {
        if (ignoreFields.indexOf(key) === -1) {
          this.queryParam[key] = ''
        }
      }
      this.queryParam.pageNum = 1
      this.queryParam.pageSize = 10
      this.queryParam.createTime = null
    },
    handleTableChange(pageNum, pageSize) {
      this.queryParam.pageNum = pageNum
      this.queryParam.pageSize = pageSize
      this.searchQuery()
    },
    handleEdit(row) {
      this.pinZ = row
      const found = statusArr.find((element) => element.itemValue === `${row.status}`)
      this.pinZ.statusText = found ? found.itemText : ''
      this.modalvisible = true
    },
    handleEdit2(row) {
      this.more = row
      this.modalvisible2 = true
    },
    handleCancel() {
      this.modalvisible = false
    },
    handleCancel2() {
      this.modalvisible2 = false
    },
    searchQuery() {
      this.loading = true
      const params = {...this.queryParam}
      const { createTime, successTime } = params

      if (createTime) {
        params.beginTime = `${moment(createTime[0]).format('YYYY-MM-DD')} 00:00:00`
        params.endTime = `${moment(createTime[1]).format('YYYY-MM-DD')} 23:59:59`
        delete params.createTime
      }

      if (successTime) {
        params.beginFinishTime = moment(successTime[0]).format('YYYY-MM-DD 00:00:00')
        params.endFinishTime = moment(successTime[1]).format('YYYY-MM-DD 23:59:59')
        delete params.successTime
      }

      getBankOrderPage(params).then((res) => {
        this.dataSource = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
  },
}
</script>
<style scoped lang="less">
.appContainer ::v-deep {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
/deep/.ant-form-item {
  margin-bottom: 14px;
}
.modalContent {
  margin-top: 30px;
  .Item {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    margin-bottom: 16px;
    .left {
      margin-right: 100px;
      width: 80px;
      text-align: right;
    }
    .right {
      text-align: left;
    }
  }
}
.modalContent2 {
  display: flex;
  .c1 {
    .Item {
      .left {
        width: 100px;
        margin-right: 80px;
        text-align: left;
      }
      .right {
        margin-right: 100px;
      }
    }
  }
  .c2 {
    .Item {
      .left {
        text-align: left;
        width: 180px;
        margin-right: 80px;
      }
      .right {
        text-align: left;
      }
    }
  }
}
</style>
