import { axios } from '@/utils/request'

export function getUserRuleList(data = {}) {
  return axios({
    url: '/userRule/getUserRulePage',
    method: 'post',
    data,
  })
}

export function saveUserRule(data = {}) {
  return axios({
    url: '/userRule/saveUserRule',
    method: 'post',
    data,
  })
}

export function updateUserRule(data = {}) {
  return axios({
    url: '/userRule/updateUserRule',
    method: 'post',
    data,
  })
}

export function checkUserRule(data = {}) {
  return axios({
    url: '/userRule/checkUserRule',
    method: 'post',
    data,
  })
}

export function updateUserRuleStatus(data = {}) {
  return axios({
    url: '/userRule/updateUserRuleStatus',
    method: 'post',
    data,
  })
}