<template>
  <a-card :bordered="false">
    <!-- 抽屉 -->
    <a-drawer title="员工管理" :width="screenWidth" @close="onClose" :visible="visible">
      <!-- 抽屉内容的border -->
      <div
        :style="{
          padding: '10px',
          border: '1px solid #e9e9e9',
          background: '#fff',
        }"
      >
        <div class="table-page-search-wrapper">
          <a-form layout="inline" :form="form" @keyup.enter.native="searchQuery">
            <a-row>
              <a-col :md="2" :sm="24">
                <a-button style="margin-bottom: 10px" type="primary" @click="handleAdd()">新增</a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div>
          <a-table
            ref="table"
            rowKey="id"
            size="middle"
            :columns="columns"
            :dataSource="dataSource"
            :pagination="ipagination"
            :loading="loading"
            bordered
            @change="handleTableChange"
          >
            <template slot="status" slot-scope="text">
              <a-badge :status="text == 1 ? 'success' : 'default'" />
              <span>{{ USER_STATUS[text] }}</span>
            </template>
            <span slot="action" slot-scope="text, record">
              <a @click="handleEdit(record)">编辑</a>
            </span>
          </a-table>

          <a-modal
            title="新增子产品"
            width="600px"
            :visible="visibleAdd"
            okText="确认"
            cancel-text="取消"
            @ok="handleOk()"
            @cancel="handleCancel()"
          >
            <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-item label="名称">
                <a-input placeholder="请输入子产品名称" v-decorator="['name', validatorRules.name]" />
              </a-form-item>
              <a-form-item label="租户id">
                <a-input placeholder="请输入子产品租户id" v-decorator="['appSsid', validatorRules.appSsid]" />
              </a-form-item>
              <a-form-item label="logo">
                <a-input placeholder="请输入子产品logo" v-decorator="['logo', validatorRules.logo]" />
              </a-form-item>
              <a-form-item label="期限">
                <a-input :placeholder="this.curDurations" v-decorator="['duration', validatorRules.duration]" />
              </a-form-item>
              <a-form-item label="包名">
                <a-input placeholder="请输入子产品包名" v-decorator="['appPkgName', validatorRules.appPkgName]" />
              </a-form-item>
              <a-form-item label="最大金额">
                <a-input placeholder="请输入子产品最大金额" v-decorator="['maxAmount', validatorRules.maxAmount]" />
              </a-form-item>
              <a-form-item label="最小金额">
                <a-input placeholder="请输入子产品最小金额" v-decorator="['minAmount', validatorRules.minAmount]" />
              </a-form-item>
              <a-form-item label="递增金额">
                <a-input placeholder="请输入子产品递增金额" v-decorator="['incrAmount', validatorRules.incrAmount]" />
              </a-form-item>
              <a-form-item label="增长额度">
                <a-input placeholder="请输入子产品增长额度" v-decorator="['incrQuota', validatorRules.incrQuota]" />
              </a-form-item>
              <a-form-item label="服务费率">
                <a-input
                  placeholder="请输入子产品服务费率"
                  v-decorator="['serviceFeeRate', validatorRules.serviceFeeRate]"
                />
              </a-form-item>
              <a-form-item label="展期服务费">
                <a-input
                  placeholder="请输入子产品展期服务费"
                  v-decorator="['diferFeeRate', validatorRules.diferFeeRate]"
                />
              </a-form-item>
              <a-form-item label="逾期费率">
                <a-input placeholder="请输入子产品逾期费率" v-decorator="['overdueRate', validatorRules.overdueRate]" />
              </a-form-item>
              <a-form-item label="日利率">
                <a-input
                  placeholder="请输入子产品日利率"
                  v-decorator="['dayInterestRate', validatorRules.dayInterestRate]"
                />
              </a-form-item>
              <a-form-item label="允许展期时间">
                <a-radio-group
                  v-model:value="allowDiferTime"
                  v-decorator="['allowDiferTime', validatorRules.allowDiferTime]"
                >
                  <a-radio value="0">不做限制</a-radio>
                  <a-radio value="1">不允许展期 </a-radio>
                  <a-radio value="2">仅到期前</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="允许提前还款">
                <a-radio-group v-model:value="allowPrepay" v-decorator="['allowPrepay', validatorRules.allowPrepay]">
                  <a-radio value="0">不允许</a-radio>
                  <a-radio value="1">允许</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="是否启用">
                <a-switch v-model:checked="detailStatus" />
              </a-form-item>
            </a-form>
          </a-modal>
        </div>
      </div>
    </a-drawer>
    <app-modal ref="modalForm" @ok="modalFormOk"></app-modal>
    <app-list ref="appList" @ok="modalFormOk"></app-list>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import { addAppSub, deleteApp, getAllSubAppById, getDurationsByAppParentId } from '@/api/system'
import { filterObj } from '@/utils/util'
import AppModal from '@/views/system/modules/AppModal'
import AppList from '@/views/system/AppList'

export default {
  name: 'subAppList',
  components: { AppList, AppModal },
  data() {
    return {
      validatorRules: {
        name: { rules: [{ required: true, message: '子产品名称不能为空!' }] },
        appSsid: { rules: [{ required: true, message: '租户id不能为空!' }] },
        logo: { rules: [{ required: true, message: 'logo不能为空!' }] },
        duration: { rules: [{ required: true, message: '期限不能为空!' }] },
        appPkgName: { rules: [{ required: true, message: '包名不能为空!' }] },
        maxAmount: { rules: [{ required: true, message: '最大金额不能为空!' }] },
        minAmount: { rules: [{ required: true, message: '最小金额不能为空!' }] },
        incrAmount: { rules: [{ required: true, message: '递增金额不能为空!' }] },
        incrQuota: { rules: [{ required: true, message: '增长额度不能为空!' }] },
        allowDiferTime: { rules: [{ required: true, message: '展期时间不能为空!' }] },
        allowPrepay: { rules: [{ required: true, message: '提前还款不能为空!' }] },
        serviceFeeRate: { rules: [{ required: true, message: '服务费率不能为空!' }] },
        diferFeeRate: { rules: [{ required: true, message: '展期服务费不能为空!' }] },
        overdueRate: { rules: [{ required: true, message: '逾期费率不能为空!' }] },
        dayInterestRate: { rules: [{ required: true, message: '日利率不能为空!' }] },
      },
      curDurations: '此应用下的产品期限为',
      allowDiferTime: undefined,
      allowPrepay: undefined,
      detailStatus: undefined,
      curId: undefined,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      dataSource: [],
      loading: false,
      columns: [
        {
          title: '应用名称',
          align: 'left',
          dataIndex: 'appName',
        },
        {
          title: '租户id',
          align: 'left',
          dataIndex: 'appSsid',
        },
        {
          title: '包名',
          align: 'left',
          dataIndex: 'appPackageName',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      queryParam: {
        appName: '',
      },
      isorter: {
        column: 'createTime',
        order: 'desc',
      },
      title: '操作',
      visible: false,
      visibleAdd: false,
      screenWidth: 800,
      appId: '',
      status: '1',
      labelCol: {
        xs: { span: 5 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
      },
      form: this.$form.createForm(this),
      USER_STATUS: {
        1: '启用',
        2: '禁用',
      },
    }
  },
  created() {
    // 当页面初始化时,根据屏幕大小来给抽屉设置宽度
    this.resetScreenSize()
  },
  methods: {
    searchQuery() {
      this.loadData(1)
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.usertName = ''
      that.queryParam.mobileNo = ''
      that.loadData(this.ipagination.current)
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams() // 查询条件
      getAllSubAppById(this.curId)
        .then((res) => {
          if (res != null) {
            this.ipagination.total = res.data.total
            this.dataSource = res.data.rows
          } else {
            this.ipagination.total = 0
            this.dataSource = []
            this.$message.error(res.msg)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    handleTableChange(pagination, filters, sorter) {
      // TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    add(appId) {
      this.appId = appId
      this.edit({})
    },
    edit(record) {
      this.curId = record.id
      console.dir(record)
      if (record.id) {
        this.appId = record.id
      }
      this.queryParam = {}
      this.form.resetFields()
      this.queryParam.appId = this.appId
      this.visible = true
      this.model = Object.assign({}, record)
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'appName', 'appSsid', 'appPackageName'))
      })
      // 当其它模块调用该模块时,调用此方法加载字典数据
      this.loadData()
    },

    getQueryParams() {
      var param = Object.assign({}, this.queryParam)
      param.pageNum = this.ipagination.current
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },

    // 添加字典数据
    handleAdd() {
      this.showModal(this.curId)
      this.$refs.modalForm.title = '新增'
    },
    handleEdit(record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
    },
    handleDelete(id) {
      deleteApp({ id }).then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.loadData()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    //获取此应用下产品的期限列表
    getDurationsByAppParentId(parentId) {
      getDurationsByAppParentId(parentId).then((res) => {
        this.curDurations += res.data.toString()
      })
    },

    //显示模态框
    showModal(id) {
      this.parentId = id
      this.getDurationsByAppParentId(id)
      this.visibleAdd = true
    },
    //关闭模态框
    handleCancel() {
      this.close()
    },
    // 确定
    handleOk() {
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          values.parentId = this.parentId
          values.allowPrepay = this.allowPrepay
          values.allowDiferTime = this.allowDiferTime
          if (this.detailStatus) {
            values.detailStatus = 1
          } else {
            values.detailStatus = 0
          }
          const formData = Object.assign(this.model, values)
          addAppSub(formData)
            .then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增子产品成功')
                this.searchQuery()
                this.$emit('ok')
              } else if (res.code === -1008) {
                this.$message.error('此租户id已存在')
              } else {
                this.$message.warning('新增子产品失败')
              }
            })
            .finally(() => {
              this.confirmLoading = false
              this.close()
            })
          this.close()
        }
      })
    },
    close() {
      this.form.resetFields()
      this.parentId = undefined
      this.allowPrepay = undefined
      this.allowDiferTime = undefined
      this.detailStatus = undefined
      this.curDurations = '此应用下的产品期限为'
      this.visibleAdd = false
    },
    onClose() {
      this.curId = undefined
      this.visible = false
      this.form.resetFields()
      this.allowPrepay = undefined
      this.allowDiferTime = undefined
      this.detailStatus = undefined
      this.curDurations = '此应用下的产品期限为'
      this.dataSource = []
    },
    // 抽屉的宽度随着屏幕大小来改变
    resetScreenSize() {
      const screenWidth = document.body.clientWidth
      if (screenWidth < 800) {
        this.screenWidth = screenWidth
      } else {
        this.screenWidth = 800
      }
    },
    // model回调
    modalFormOk() {
      this.loadData()
    },
  },
}
</script>
<style scoped></style>
