import { axios } from '@/utils/request'

export function getChannelList(data = {}) {
  return axios({
    url: '/channel/getChannelPage',
    method: 'post',
    data,
  })
}

// 新增渠道
export function addNewChannel(data) {
  return axios({
    url: '/channel/saveChannel',
    method: 'post',
    data,
  })
}

// 更新渠道
export function updateChannel(data) {
  return axios({
    url: '/channel/updateChannel',
    method: 'post',
    data,
  })
}

// 获取所有渠道
export function getAllChannels(data = {}) {
  return axios({
    url: '/channel/getAllChannel',
    method: 'post',
    data,
  })
}

export function bindChannelByBank(data = {}) {
  return axios({
    url: '/channel/saveBankChannelRelationForBank',
    method: 'post',
    data,
    type: 'form',
  })
}

export function getChannelsByBankId(data = {}) {
  return axios({
    url: '/channel/getChannelsByBankId',
    method: 'post',
    data,
    type: 'form',
  })
}

export function switchPayOutChannel(data = {}) {
  return axios({
    url: '/channel/switchPayOutChannel',
    method: 'post',
    data,
    type: 'form',
  })
}
