import { axios } from '@/utils/request'

export function getBankList(data = {}) {
  return axios({
    url: '/bank/getBankPage',
    method: 'post',
    data,
  })
}

export function addNewBank(data) {
  return axios({
    url: '/bank/saveBank',
    method: 'post',
    data,
  })
}

export function updateBank(data) {
  return axios({
    url: '/bank/updateBank',
    method: 'post',
    data,
  })
}

export function getAllBanks(data = {}) {
  return axios({
    url: '/bank/getAllBanks',
    method: 'post',
    data,
  })
}

export function getBanksByChannel(data = {}) {
  return axios({
    url: '/bank/getBanksByChannel',
    method: 'post',
    type: 'form',
    data,
  })
}
