<template>
  <div>
      <a-form :form="loanform" @submit="TapSbumit">
        <a-row :gutter="12">
          <a-col :span="6">
            <a-form-item label="文件名" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
              <a-input
                oninput="value=value.replace(/^\s+/,'').replace(/\s+$/,'')"
                v-decorator.trim="['fileName']"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6" >
            <a-form-item label="文件类型" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
              <a-select v-decorator="['fileType']">
                <a-select-option value="1"> 放款文件 </a-select-option>
                <a-select-option value="2"> 放款回传文件 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="原文件名" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
              <a-input
                oninput="value=value.replace(/^\s+/,'').replace(/\s+$/,'')"
                v-decorator.trim="['originalFileName']"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
            <a-col :span="6" >
            <a-form-item label="开始时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
              <a-date-picker size="default" v-decorator.trim="['beginTime']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="结束时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
              <a-date-picker size="default" v-decorator.trim="['endTime']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <div class="btn">
              <a-form-item class="reset" :wrapper-col="{ span: 24, offset: 0 }">
                <a-button type="primary" icon="search" html-type="submit"> 查询 </a-button>
              </a-form-item>

              <a-form-item class="reset" :wrapper-col="{ span: 24, offset: 0 }">
                <a-button type="primary" icon="reload" @click="Tapreset"> 重置 </a-button>
              </a-form-item>

              <a-form-item class="reset" :wrapper-col="{ span: 24, offset: 0 }">
                <a-button type="primary" @click="Tapgenerate"> 生成放款文件 </a-button>
              </a-form-item>
              <a-form-item class="reset" :wrapper-col="{ span: 24, offset: 0 }">
                <a-button type="primary" @click="TapLoad"> 上传回传放款文件 </a-button>
              </a-form-item>
            </div>
          </a-col>
        </a-row>
        <!-- <a-row>
          <a-col :md="24">
            <div class="btn">
              <a-form-item class="reset" :wrapper-col="{ span: 24, offset: 0 }">
                <a-button type="primary" icon="search" html-type="submit"> 查询 </a-button>
              </a-form-item>

              <a-form-item class="reset" :wrapper-col="{ span: 24, offset: 0 }">
                <a-button type="primary" icon="reload" @click="Tapreset"> 重置 </a-button>
              </a-form-item>

              <a-form-item class="reset" :wrapper-col="{ span: 24, offset: 0 }">
                <a-button type="primary" @click="Tapgenerate"> 生成放款文件 </a-button>
              </a-form-item>
              <a-form-item class="reset" :wrapper-col="{ span: 24, offset: 0 }">
                <a-button type="primary" @click="TapLoad"> 上传回传放款文件 </a-button>
              </a-form-item>
            </div>
          </a-col>
        </a-row> -->
      </a-form>

    <div>
      <a-table rowKey="id" :pagination="false" :loading="loading" :columns="columns" :data-source="loandata" bordered>
        <span slot="fileTypeval" slot-scope="text, record">
          <p>{{ record.fileType == 1 ? '放款文件' : '放款回传文件' }}</p>
        </span>

        <span slot="address" slot-scope="text, record">
          <a-button type="primary" ghost @click="TapDownload(record)"> 下载 </a-button>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right">
        <v-pagination
          v-if="total"
          :page-num="FileItem.pageNum"
          :page-size="FileItem.pageSize"
          :total="total"
          @change="handleTableChange"
        />
      </div>
    </div>
    <div>
      <a-modal
        title="上传回传放款文件"
        okText="提交"
        :destroyOnClose="true"
        :visible="UploadModel"
        :confirm-loading="confirmLoading"
        @ok="uploadSubmit"
        @cancel="handUpload"
      >
        <div>
          渠道：
          <a-select default-value="monnet" style="width: 120px; margin-bottom: 20px" @change="handleChange">
            <a-select-option v-for="(item, key) in ChannelList" :value="item.channel" :key="key">
              {{ item.channel }}
            </a-select-option>
          </a-select>
        </div>

        <a-upload-dragger
          name="files"
          :multiple="false"
          :fileList="uploadlist"
          :before-upload="customBeforeUpload"
          :remove="fielRemove"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
        </a-upload-dragger>
      </a-modal>
    </div>
    <div>
      <a-modal
        title="生成放款文件"
        :footer="false"
        width="65%"
        :visible="DocumentModel"
        :confirm-loading="confirmLoading"
        @cancel="handDoc"
      >
        <div>
          <a-form
            :form="loanDocumentForm"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 14, offset: 1 }"
            @submit="TapDocument"
          >
            <a-row>
              <a-col :md="8" :sm="8">
                <a-form-item label="渠道">
                  <a-select v-model:value="path">
                    <a-select-option v-for="(item, key) in ChannelList" :value="item.channel" :key="key">
                      {{ item.channel }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="8">
                <a-form-item label="开始时间">
                  <a-date-picker v-decorator.trim="['beginTime']" />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="8">
                <a-form-item label="结束时间">
                  <a-date-picker v-decorator.trim="['endTime']" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="24">
                <div class="btn">
                  <a-form-item :wrapper-col="{ span: 24, offset: 0 }">
                    <a-button type="primary" html-type="submit"> 生成 </a-button>
                  </a-form-item>

                  <a-form-item class="reset" :wrapper-col="{ span: 24, offset: 0 }">
                    <a-button type="primary" icon="reload" @click="resetDocument"> 重置 </a-button>
                  </a-form-item>
                </div>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { exportFile } from '@/api/exportFile'
import { getDocumentList, getPayoutExcel, postPayoutExcel, getDownloadloan, postfileloan } from '@/api/OfflineLoan.js'
import { getAllChannels } from '@/api/channel.js'
import moment from 'moment'
import cookie from '@/utils/cookie'
const columns = [
  {
    title: '创建时间',
    dataIndex: 'fileTime',
    width: '150px',
  },
  {
    title: '原文件名',
    dataIndex: 'originalFileName',
    width: '200px',
  },
  {
    title: '文件名',
    dataIndex: 'fileName',
  },
  {
    title: '文件类型',
    dataIndex: 'fileType',

    scopedSlots: { customRender: 'fileTypeval' },
  },
  {
    title: '操作',
    dataIndex: 'address',
    scopedSlots: { customRender: 'address' },
    width: 120,
    align: 'center',
  },
]
function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  data() {
    return {
      loanfilename: '2024-1-2',
      path: 'monnet',
      total: 0,
      active: {},
      some: {},
      UploadModel: false,
      DocumentModel: false,
      loading: false,
      Excelname: '',
      requestHeaders: '',
      uploadlist: [],
      loandata: [],
      ChannelList: [],
      columns,
      loanform: this.$form.createForm(this),
      loanDocumentForm: this.$form.createForm(this),
      FileItem: {
        pageNum: 1,
        pageSize: 10,
      },
      confirmLoading: false,
      channel: 'monnet',
    }
  },
  methods: {
    TapSbumit(e) {
      e.preventDefault()
      this.loanform.validateFields((err, values) => {
        console.log(values)
        this.active = { ...values, ...{ pageNum: 1, pageSize: 10 } }
        console.log(this.active)
        if (this.active.beginTime) {
          this.active.beginTime = moment(this.active.beginTime).format('YYYY-MM-DD HH:mm:SS')
        }
        if (this.active.endTime) {
          this.active.endTime = moment(this.active.endTime).format('YYYY-MM-DD HH:mm:SS')
        }
        this.GetPostloan(this.active)
      })
    },
    //
    TapDocument(e) {
      e.preventDefault()
      this.loanDocumentForm.validateFields((err, values) => {
        this.some = { ...values }
        if (!values.path) {
          this.some.channel = this.path
        }

        if (this.some.beginTime) {
          this.some.beginTime = moment(this.some.beginTime).format('YYYY-MM-DD HH:mm:SS')
        }
        if (this.some.endTime) {
          this.some.endTime = moment(this.some.endTime).format('YYYY-MM-DD HH:mm:SS')
        }
        for (let i in this.some) {
          if (this.some[i] == undefined) {
            this.some[i] = ''
          }
        }
        this.GetExcel(this.some)
      })
    },
    // 下载
    TapDownload(val) {
      this.Excelname = val.fileName
      this.GetDloan(val.fileUrl)
    },
    // 上传
    TapLoad(val) {
      this.UploadModel = true
      this.channel = 'monnet'
    },
    // 重置
    Tapreset() {
      this.loanform.resetFields()
      this.active = {}
      this.GetPostloan({ pageNum: 1, pageSize: 10 })
      this.$message.success('重置成功')
    },
    // 重置放款文件
    resetDocument() {
      this.path = ''
      this.loanDocumentForm.resetFields()
    },
    // 生成
    Tapgenerate() {
      this.DocumentModel = true
    },

    // 文件信息
    async GetPostloan(data) {
      this.loading = true
      await getDocumentList(data).then((res) => {
        if (res.code == 2000) {
          if (res.data) {
            this.loandata = res.data.list
            this.total = res.data.page.total
          } else {
            this.loandata = []
            this.total = 0
          }
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      })
    },
    // 渠道信息
    async getChannel() {
      const res = await getAllChannels()
      this.ChannelList = res.data
    },
    // 下载上传文件
    async GetExcel(data) {
      this.DocumentModel = true
      let GetExcelitem = new FormData()
      GetExcelitem.append('channel', data.channel)
      GetExcelitem.append('beginTime', data.beginTime)
      GetExcelitem.append('endTime', data.endTime)
      await getPayoutExcel(GetExcelitem).then((res) => {
        if (res && res.type == 'text/xml') {
          let time = moment().format('YYYY-MM-DD')
          exportFile(res, time + '.xlsx')
          this.$message.success('下载成功')
          this.DocumentModel = false
        } else {
          const fileReader = new FileReader()
          fileReader.readAsText(res, 'utf-8')
          fileReader.onload = () => {
            const result = JSON.parse(fileReader.result)
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 下载
    async GetDloan(data) {
      let uploaditem = new FormData()
      uploaditem.append('path', data)
      await getDownloadloan(uploaditem)
        .then((res) => {
          console.log(res)
          if (res && res.type == 'text/xml') {
            exportFile(res, this.Excelname)
            this.$message.success('下载成功')
          } else {
            const fileReader = new FileReader()
            fileReader.readAsText(res, 'utf-8')
            fileReader.onload = () => {
              const result = JSON.parse(fileReader.result)
              this.$message.error(result.msg)
            }
          }
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    },
    uploadSubmit(e) {
      this.confirmLoading = true
      this.UploadModel = true
      let data = new FormData()
      data.append('channel', this.channel)
      this.uploadlist.forEach((item) => {
        data.append('files', item)
      })

      if (this.uploadlist.length == 0) {
        this.$message.error('请选择上传文件')
        this.confirmLoading = false
        return
      }
      postPayoutExcel(data).then((res) => {
        console.log(res)
        this.uploadLoading = false
        if (res.code == 2000) {
          this.$message.success('上传成功')
          this.uploadlist = []
          this.GetPostloan(this.FileItem)
          this.UploadModel = false
        } else {
          this.$message.error(res.msg)
        }
      })
      this.confirmLoading = false
    },
    // Model关闭
    handUpload(e) {
      this.UploadModel = false
    },
    handDoc() {
      this.DocumentModel = false
      this.path = 'monnet'
    },
    customBeforeUpload(file) {
      this.uploadlist.push(file)
      return false
    },
    fielRemove(file) {
      this.uploadlist.forEach((item, index) => {
        if (item == file) {
          this.uploadlist.splice(index, 1)
        }
      })
    },
    handleTableChange(pageNum, pageSize) {
      this.FileItem = { ...this.active }
      this.FileItem.pageNum = pageNum
      this.FileItem.pageSize = pageSize
      this.GetPostloan(this.FileItem)
    },
    handleChange(val) {
      this.channel = val
      console.log(this.channel)
    },
  },
  created() {
    this.GetPostloan(this.FileItem)
    this.requestHeaders = {
      Authorization: 'Bearer ' + cookie.get('token'), //页面token
    }
    this.getChannel()
  },
}
</script>

<style>
.btn {
  display: flex;
  justify-content: flex-end;
}

.reset {
  margin-left: 10px !important;
}

th.column-money,
td.column-money {
  text-align: right !important;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
