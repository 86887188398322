<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="7" :sm="8">
            <a-form-item label="字典名称">
              <a-input placeholder="请输入字典名称" v-model="queryParam.dictName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="8">
            <a-form-item label="字典编号">
              <a-input placeholder="请输入字典编号" v-model="queryParam.dictCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="8">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <div class="table-operator" style="border-top: 5px">
        <a-button @click="handleAdd" type="primary" icon="plus">添加</a-button>
      </div>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">
            <a-icon type="edit" />
            编辑
          </a>
          <a-divider type="vertical" />
          <a @click="editDictItem(record)"><a-icon type="setting" /> 字典配置</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="clearCache(record.id)"> 清除缓存</a>
        </span>
      </a-table>
    </div>
    <dict-modal ref="modalForm" @ok="modalFormOk"></dict-modal>
    <dict-item-list ref="dictItemList"></dict-item-list>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getDict, deleteDict, clearDictCache } from '@/api/system'
import DictModal from './modules/DictModal'
import DictItemList from './DictItemList'
export default {
  name: 'DictList',
  components: { DictModal, DictItemList },
  data() {
    return {
      visible: false,
      // 分页参数
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      dataSource: [],
      // 查询条件
      queryParam: {
        dictCode: '',
        dictName: '',
      },
      loading: false,
      // 表头
      columns: [
        {
          title: '字典名称',
          align: 'left',
          dataIndex: 'dictName',
        },
        {
          title: '字典编号',
          align: 'left',
          dataIndex: 'dictCode',
        },
        {
          title: '描述',
          align: 'left',
          dataIndex: 'description',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      isorter: {
        column: 'createTime',
        order: 'desc',
      },
      // dict: '',
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 },
      },
    }
  },
  created() {
    this.loadData(1)
  },
  methods: {
    searchQuery() {
      this.loadData(1)
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      this.loading = true
      var params = this.getQueryParams() // 查询条件
      getDict(Object.assign({}, params))
        .then((res) => {
          if (res.code === 2000) {
            this.ipagination.total = res.data.total
            this.dataSource = res.data.rows
          } else {
            this.ipagination.total = 0
            this.dataSource = []
            this.$message.error(res.msg)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    getQueryParams() {
      var param = Object.assign({}, this.queryParam, this.isorter)
      param.pageNum = this.ipagination.current
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },
    handleTableChange(pagination, filters, sorter) {
      // TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    handleAdd() {
      this.$refs.modalForm.add()
      this.$refs.modalForm.title = '新增'
    },
    handleEdit(record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
    },
    handleDelete(id) {
      deleteDict({ id }).then((res) => {
        if (res.code === 2000) {
          this.$message.success(res.msg)
          this.loadData()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    clearCache(id) {
      clearDictCache({ id }).then((res) => {
        if (res.code === 2000) {
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 取消选择
    cancelDict() {
      this.dict = ''
      this.visible = false
      this.loadData()
    },
    // 编辑字典数据
    editDictItem(record) {
      this.$refs.dictItemList.edit(record)
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.dictName = ''
      that.queryParam.dictCode = ''
      that.loadData(this.ipagination.current)
    },
    // model回调
    modalFormOk() {
      this.loadData()
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/common.less';
</style>
