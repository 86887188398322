<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
      <a-row dispaly="flex" justify="space-around">
        <a-col :span="8">
          <a-form-item label="开始时间">
            <a-date-picker v-model="queryParams.startTime" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="结束时间">
            <a-date-picker v-model="queryParams.endTime" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="渠道筛选">
            <a-select style="width: 150px" v-model="queryParams.channel" allowClear>
              <a-select-option v-for="(item, index) in channels" :key="index" :value="item.channel">{{ item.channel }} </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item label="异常类型">
            <a-select style="width: 180px" v-model="queryParams.errorType" allowClear>
              <a-select-option v-for="item in settlecomparisonresut" :key="item.itemValue" :value="item.itemValue"
                >{{ item.itemText }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item label="商户">
            <a-input v-model="queryParams.userNo" />
          </a-form-item>
        </a-col>

        <a-col :span="8" v-if="settlecomparisonresut">
          <a-form-item label="类型">
            <a-select style="width: 180px" v-model="queryParams.transType" allowClear>
              <a-select-option v-for="item in transtypeArr" :key="item.itemValue" :value="item.itemValue"
                >{{ item.itemText }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
            <a-button type="primary" icon="plus" @click="() => {queryParams.pageNum = 1; searchQuery()}">查询</a-button>
          </span>

          <span style="float: left; overflow: hidden; margin-left: 20px;" class="table-page-search-submitButtons">
            <a-button type="primary" @click="daochu" icon="export">导出</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <!-- // <div style="margin-bottom: 10px"><span>未平账个数: 100</span> <span>已平账金额: 1000000</span></div> -->
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :scroll="{ x: 1000 }"
      :pagination="false"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)"> 处理 </a>
      </span>
      <span slot="ruleCode" slot-scope="text, record">
        {{ record.ruleCode == 1002 ? '坐扣收费' : '阶梯收费' }}
      </span>
      <span slot="bizType" slot-scope="text, record">
        <a-tooltip>
          <template slot="title"> prompt text </template>
          {{ record.bizType == 1 ? '代收' : '代付' }}
        </a-tooltip>
      </span>
      <span slot="createTime" slot-scope="text, record">
        {{ moment(record.createTime).format('YYYY-MM-DD HH:mm:SS') }}
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="queryParams.pageNum"
        :page-size="queryParams.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getAllChannels } from '@/api/channel'
import { getMerchantPage, getOrderBillDetailPage, exportOrderBillDetailList, findDictItemByCode } from '@/api/pay'
import ATip from 'ant-design-vue/es/tooltip'
import moment from 'moment'
import { message } from 'ant-design-vue'
import { exportFile } from '@/api/exportFile'

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
}

export default {
  name: 'SettleError',
  components: {
    ATip,
  },
  data() {
    return {
      moment,
      transtypeArr: [],
      queryParams: {
        userNo: '',
        startTime: '',
        endTime: '',
        transType: '',
        errorType: '',
        channel: '',
        pageNum: 1,
        pageSize: 10,
      },
      settlecomparisonresut: [], //异常类型
      dataSource: [],
      channels: [],
      // 商户
      commercials: [],
      // 异常类型
      exceptions: [],
      loading: false,
      total: 0,
      columns: [
        {
          title: '日期',
          dataIndex: 'createTime',
          // scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '平台订单号',
          dataIndex: 'orderNo',
        },
        {
          title: '平台订单状态',
          dataIndex: 'orderStatus',
        },
        {
          title: '三方订单状态',
          dataIndex: 'bankOrderStatus',
        },
        {
          title: '异常类型',
          dataIndex: 'result',
        },
        {
          title: '金额',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '渠道',
          dataIndex: 'channel',
          scopedSlots: { customRender: 'channel' },
        },
        {
          title: '状态',
          dataIndex: 'billStatus',
          scopedSlots: { customRender: 'billStatus' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      confirmLoading: false,
      userName: localStorage.getItem('userName') || '',
    }
  },
  computed: {
    ...mapGetters(['userNo', 'userId']),
  },
  created() {
    findDictItemByCode('settleComparisonResut').then((res) => {
      this.settlecomparisonresut = res.data.settlecomparisonresut
    })
    findDictItemByCode('transType').then((res) => {
      this.transtypeArr = res.data.transtype
    })
    this.init()
  },
  methods: {
    daochu() {
      if (this.queryParams.transType == '') {
        message.error('请选择类型')
        return
      }
      this.loading = true
      const params = { ...this.queryParams }
      let timeStr = ''
      if (params.startTime) {
        const time = moment(params.startTime).format('YYYY-MM-DD')
        timeStr += time
      }
      if (params.endTime) {
        const time = moment(params.endTime).format('YYYY-MM-DD')
        timeStr = timeStr + '~' + time
      }
      let fileName = params.transType == 'payIn' ? '收款对账结果' : '放款对账结果'
      exportOrderBillDetailList(params)
        .then((res) => {
          exportFile(res, fileName + timeStr)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // daochu() {
    //   if(this.queryParams.transType==""){
    //     message.error("请选择类型")
    //     return
    //   }
    //     this.loading = true
    //   exportOrderBillDetailList(this.queryParams).then((res) => {
    //     if (!res) {
    //       message.error(res.msg)
    //     } else {
    //       const time = moment(params.endTime).format('YYYY-MM-DD')
    //       let timeStr = timeStr + '~' + time
    //       const elink = document.createElement('a')
    //       elink.download = decodeURIComponent("对账结果"+timeStr)
    //       elink.style.display = 'none'
    //       elink.href = URL.createObjectURL(res)
    //       document.body.appendChild(elink)
    //       elink.click()
    //       URL.revokeObjectURL(elink.href) // 释放URL 对象
    //       document.body.removeChild(elink)
    //     }
    //     this.loading = false
    //   })
    // },
    init() {
      this.getChannels()
      this.getCommercials()
      this.getExceptions()
    },
    async getChannels() {
      const res = await getAllChannels()
      if (res.code === '2000') {
        this.channels = res.data
      }
    },
    async getCommercials() {
      const res = await getMerchantPage({ pageNum: 1, pageSize: 1000 })
      if (res.code === '2000') {
        this.commercials = res.data.list
      }
    },
    getExceptions() {},
    handleTableChange(pageNum, pageSize) {
      this.queryParams.pageNum = pageNum
      this.queryParams.pageSize = pageSize
      this.searchQuery()
    },
    handleEdit(row) {
      console.log('---row---')
    },
    createRule() {
      this.modalvisible = true
    },
    searchQuery() {
      this.loading = true
      getOrderBillDetailPage(this.queryParams).then((res) => {
        console.log(111111111111, res)
        if (res.code != '2000') {
          message.error(res.msg)
        } else {
          this.dataSource = res.data.list
          this.total = res.data.total
          this.loading = false
        }
        this.loading = false
      })
    },
  },
}
</script>
<style scoped lang="less">
.appContainer ::v-deep {
  .ant-form-item {
    margin-bottom: 14px;
  }
}

/deep/ .ant-form-item {
  margin-bottom: 14px;
}
</style>
