<template>
    <div>
      <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
        <a-row dispaly="flex" justify="space-around">
          <a-col :span="6">
            <a-form-item label="开始时间">
              <a-date-picker v-model="queryParams.startTime" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="结束时间">
              <a-date-picker v-model="queryParams.endTime" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="渠道筛选">
              <a-select style="width: 150px" v-model="queryParams.channel" allowClear>
                <a-select-option v-for="(item, index) in channels" :key="index" :value="item.channel">{{ item.channel }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="结算状态">
              <a-select style="width: 150px" v-model="queryParams.settleStatus" allowClear>
                <a-select-option v-for="(item, index) in settleStatusArr" :key="index" :value="item.itemValue">{{ item.itemText }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row dispaly="flex" justify="space-between">
          <a-col :span="2">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" icon="plus" @click="() => {queryParams.pageNum = 1; searchQuery()}">查询</a-button>
            </span>
          </a-col>
          <a-col :span="2">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" icon="plus" @click="settleAll">结算</a-button>
            </span>
          </a-col>
          <a-col :span="3">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="visible = true" icon="export">上传结算文件</a-button>
            </span>
          </a-col>
          <a-col :span="3">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="daochu" icon="export">下载结算模板</a-button>
            </span>
          </a-col>
          <a-col :span="3">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="exportSettleDetail" icon="export">导出结算详情</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :loading="loading"
        :scroll="{ x: 1000 }"
        :pagination="false"
        bordered
        :row-selection="rowSelection"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ moment(record.createTime).format('YYYY-MM-DD HH:mm:SS') }}
        </span>
        <span slot="settleStatus" slot-scope="text, record">
          {{ record.settleStatus == '1' ? '未结算': '已结算' }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button type="primary" @click="handleEdit(record)" :disabled="record.settleStatus == '2'">结算</a-button>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right">
        <v-pagination
          v-if="total"
          :page-num="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          :total="total"
          @change="handleTableChange"
        />
      </div>
      <a-modal
        title="上传结算文件"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="visible = false"
      >
      <a-upload
        name="file"
        :file-list="fileList"
        :remove="handleRemove" 
        @change="handleChange"
        :before-upload="beforeUpload"
      >
        <a-button> <a-icon type="upload" /> 上传 </a-button>
      </a-upload>
      </a-modal>
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex'
  import { getAllChannels } from '@/api/channel'
  import {
    getMerchantPage,
    getSettlePage,
    downloadSettleTemplate,
    findDictItemByCode,
    activeSettle,
    uploadSettleExcel,
    exportOrderList
  } from '@/api/pay'
  import ATip from 'ant-design-vue/es/tooltip'
  import moment from 'moment'
  import { message } from 'ant-design-vue'
  import { exportFile } from '@/api/exportFile'
  import { exportSettleList } from '@/api/settle'
  
  export default {
    name: 'SettleActive',
    components: {
      ATip,
    },
    data() {
      return {
        moment,
        visible: false,
        transtypeArr: [],
        settleStatusArr: [],
        fileList: [],
        selectedRowKeys: [],
        queryParams: {
          userNo: '',
          startTime: '',
          endTime: '',
          settleStatus: '',
          channel: '',
          pageNum: 1,
          pageSize: 10,
        },
        settlecomparisonresut: [], //异常类型
        dataSource: [],
        channels: [],
        // 异常类型
        exceptions: [],
        loading: false,
        total: 0,
        columns: [
          {
            title: '渠道流水号',
            dataIndex: 'bankFlowNo',
          },
          {
            title: '365银行订单号',
            dataIndex: 'orderNo',
          },

          {
            title: '渠道',
            dataIndex: 'channel',
          },
          {
            title: '交易金额',
            dataIndex: 'amount',
          },
          {
            title: '商户手续费金额',
            dataIndex: 'fee',
          },
          {
            title: '结算状态',
            dataIndex: 'settleStatus',
            scopedSlots: { customRender: 'settleStatus' },
          },
          {
            title: '创建时间',
            dataIndex: 'createTime',
          },
          {
            title: '结算时间',
            dataIndex: 'settleTime',
          },
          {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
        ],
        confirmLoading: false,
        userName: localStorage.getItem('userName') || '',
      }
    },
    computed: {
      ...mapGetters(['userNo', 'userId']),
      rowSelection() {
        return {
          onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.selectedRowKeys = selectedRows.map(item => {
              return item.id
            })
            console.log(this.selectedRowKey)
          },
          getCheckboxProps: record => ({
            props: {
              disabled: record.settleStatus == '2', // Column configuration not to be checked
              settleStatus: record.settleStatus,
            },
          }),
        };
      },
    },
    created() {
      findDictItemByCode('settleComparisonResut').then((res) => {
        this.settlecomparisonresut = res.data.settlecomparisonresut
      })
      findDictItemByCode('transType').then((res) => {
        this.transtypeArr = res.data.transtype
      })
      findDictItemByCode('settleStatus').then((res) => {
        this.settleStatusArr = res.data.settlestatus
      })
      this.init()
    },
    methods: {
      exportSettleDetail() {
        this.loading = true
        const params = { ...this.queryParams }
        delete params.pageNum
        delete params.pageSize

        let timeStr = ''
        if (params.createTime) {
          params.beginTime = `${moment(params.createTime[0]).format('YYYY-MM-DD')} 00:00:00`
          params.endTime = `${moment(params.createTime[1]).format('YYYY-MM-DD')} 23:59:59`
          delete params.createTime
        }

        exportSettleList(params)
          .then((res) => {
            if (res && res.size > 0) {
              exportFile(res, '结算详情')
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      },

      handleRemove(file) {
        this.fileList = []; 
      },
      beforeUpload(file) {
        this.fileList = [file];
        return false;
      },
      handleOk() {
        uploadSettleExcel({file: this.fileList[0]}).then((res) => {
          console.log(res)
          if (res.code === '2000') {
            message.success('上传成功')
            this.visible = false
            this.searchQuery()
          } else {
            message.error(res.msg)
          }
        })
      },
      handleChange(){
        console.log(this.selectedRowKeys, 'selectedRowKeys') // 选中的role
      },
      settleAll() {
        this.loading = true
        activeSettle([...this.selectedRowKeys]).then((res) => {
          if (res.code === '2000') {
            message.success('结算成功')
            this.searchQuery()
          } else {
            message.error(res.msg)
          }
          this.loading = false
        })
        .catch(() => {
            this.loading = false
          })
      },
      handleEdit(record) {
        this.loading = true
        activeSettle([record.id]).then((res) => {
          if (res.code === '2000') {
            message.success('结算成功')
            this.searchQuery()
          } else {
            message.error(res.msg)
          }
          this.loading = false
        })
        .catch(() => {
            this.loading = false
          })
      },
      daochu() {
        this.loading = true
        const params = { ...this.queryParams }
        let timeStr = ''
        if (params.startTime) {
          const time = moment(params.startTime).format('YYYY-MM-DD')
          timeStr += time
        }
        if (params.endTime) {
          const time = moment(params.endTime).format('YYYY-MM-DD')
          timeStr = timeStr + '~' + time
        }
        let fileName = '结算模板' 
        downloadSettleTemplate(params)
          .then((res) => {
            exportFile(res, fileName + timeStr)
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      },
      init() {
        this.getChannels()
        this.searchQuery()
      },
      async getChannels() {
        const res = await getAllChannels()
        if (res.code === '2000') {
          this.channels = res.data
        }
      },
      handleTableChange(pageNum, pageSize) {
        this.queryParams.pageNum = pageNum
        this.queryParams.pageSize = pageSize
        this.searchQuery()
      },
      createRule() {
        this.modalvisible = true
      },
      searchQuery() {
        this.loading = true
        getSettlePage(this.queryParams).then((res) => {
          if (res.code != '2000') {
            message.error(res.msg)
          } else {
            this.dataSource = res.data.list
            this.total = res.data.total
            this.loading = false
          }
          this.selectedRowKeys = []
          this.loading = false
        })
      },
    },
  }
  </script>
  <style scoped lang="less">
  .appContainer ::v-deep {
    .ant-form-item {
      margin-bottom: 14px;
    }
  }
  
  /deep/ .ant-form-item {
    margin-bottom: 14px;
  }
  </style>
  