<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
      <a-row>
        <a-col :span="5">
          <a-form-item label="渠道筛选" >
            <a-select style="width: 200px" allowClear v-model="queryParams.channel" @change="onChannelChange">
              <a-select-option :value="item.channel" v-for="(item, key) in channels" :key="key"
                >{{ item.channel }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="银行编号" >
            <a-select style="width: 150px" allowClear v-model="queryParams.bankNo" @change="onBankNoChange">
              <a-select-option :value="item.bankNo" v-for="(item, key) in banks" :key="key"
                >{{ item.bankNo }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="银行编码" >
            <a-select style="width: 150px" allowClear v-model="queryParams.bankCode" @change="onBankCodeChange">
              <a-select-option :value="item.bankCode" v-for="(item, key) in banks" :key="key"
                >{{ item.bankCode }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item label="银行名称">
            <a-select style="width: 200px" allowClear v-model="queryParams.bankName" @change="onBankNameChange">
              <a-select-option :value="item.bankName" v-for="(item, key) in banks" :key="key"
                >{{ item.bankName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
            <a-button type="primary" @click="() => {queryParams.pageNum = 1; searchQuery()}" icon="search">查询</a-button>
            <a-button type="primary" @click="onReset" icon="reload" style="margin-left: 8px">重置</a-button>
            <a-button type="primary" style="margin-left: 8px" @click="createRule">新增</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :scroll="{ x: 1000 }"
      :pagination="false"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="primary" ghost @click="handleEdit(record)"> 编辑 </a-button>
        <a-button type="primary" ghost style="margin-left: 5px" slot="status" @click="onOpenOrDisable(record)">
          {{ record.status == 1 ? '禁用' : '启用' }}</a-button
        >
      </span>
      >
      <span slot="status" slot-scope="text, record">
        {{ record.status == 1 ? '启用' : status == 2 ? '禁用' : '无状态' }}
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="queryParams.pageNum"
        :page-size="queryParams.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
    <a-modal :visible="modalvisible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <a-form :form="form2">
        <a-form-item label="银行编号">
          <a-input
            placeholder="银行编号"
            v-decorator="['bankNo', { rules: [{ required: true, message: '请输入银行编号!' }] }]"
          />
        </a-form-item>
        <a-form-item label="银行编码">
          <a-input
            placeholder="银行编码"
            v-decorator="['bankCode', { rules: [{ required: true, message: '请输入银行编码!' }] }]"
          />
        </a-form-item>
        <a-form-item label="银行名称">
          <a-input
            placeholder="银行名称"
            v-decorator="['bankName', { rules: [{ required: true, message: '请输入银行名称!' }] }]"
          />
        </a-form-item>
        <a-form-item label="状态">
          <a-select ref="select" style="width: 120px" allowClear v-model="modalForm.status">
            <a-select-option value="1">启用</a-select-option>
            <a-select-option value="2">禁用</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="渠道">
          <a-select mode="multiple" style="width: 300px" allowClear v-model="modalForm.channel">
            <a-select-option :value="item.id" v-for="(item, key) in channels" :key="key">{{
              item.channel
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { getAllChannels, bindChannelByBank, getChannelsByBankId } from '@/api/channel'
import { getAllBanks, getBanksByChannel, getBankList, addNewBank, updateBank } from '@/api/bank'
import { message } from 'ant-design-vue/es'

export default {
  name: 'BankList',
  data() {
    return {
      queryParams: {
        channel: '',
        bankNo: '',
        bankName: '',
        bankCode: '',
        pageNum: 1,
        pageSize: 10,
      },
      dataSource: [],
      channels: [],
      banks: [],
      loading: false,
      total: 0,
      columns: [
        { title: '序号', dataIndex: 'id' },
        {
          title: '银行编号',
          dataIndex: 'bankNo',
        },
        {
          title: '银行编码',
          dataIndex: 'bankCode',
        },
        {
          title: '银行名称',
          dataIndex: 'bankName',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 200,
          align: 'center',
        },
      ],
      confirmLoading: false,
      modalvisible: false,
      edit: false,
      editChannelId: '',
      modalForm: {
        status: '1',
        channel: [],
      },
      form2: this.$form.createForm(this, { name: 'coordinated' }),
    }
  },
  created() {
    this.init()
    this.getAllBanks()
  },
  methods: {
    async init() {
      const res = await getAllChannels()
      if (res.code === '2000') {
        this.channels = res.data
      }
    },
    async getAllBanks() {
      const res = await getAllBanks()
      if (res.code === '2000') {
        this.banks = res.data
      }
    },
    async getChannelsWithBankId(bankId) {
      try {
        const res = await getChannelsByBankId({ bankId })
        const { code, data } = res
        if (code === '2000') {
          let arr = []
          data.forEach((v, i) => arr.push(v.id))
          this.modalForm.channel = arr
        }
      } catch (error) {}
    },
    async onChannelChange(channel) {
      const res = await getBanksByChannel({ channel })
      if (res.code === '2000') {
        this.banks = res.data
        this.queryParams.bankCode = ''
        this.queryParams.bankName = ''
        this.queryParams.bankNo = ''
      }
    },
    onBankNameChange(val) {},
    onBankCodeChange(val) {
      if (!val) {
        this.queryParams.bankName = ''
        this.queryParams.bankNo = ''
        return
      }
      const item = this.banks.filter((item) => item.bankCode === val)
      if (item) {
        this.queryParams.bankName = item[0].bankName
        this.queryParams.bankNo = item[0].bankNo
      }
    },
    onBankNoChange(val) {
      if (!val) {
        this.queryParams.bankName = ''
        this.queryParams.bankCode = ''
        return
      }
      const item = this.banks.filter((item) => item.bankNo === val)
      if (item) {
        this.queryParams.bankCode = item[0].bankCode
        this.queryParams.bankName = item[0].bankName
      }
    },
    onReset() {
      this.queryParams.bankNo = ''
      this.queryParams.bankName = ''
      this.queryParams.bankCode = ''
      this.queryParams.channel = ''
      this.dataSource = []
      this.queryParam.pageNum = 1
      this.queryParam.pageSize = 10
      this.getAllBanks()
    },
    async onOpenOrDisable(record) {
      const params = { ...record }
      params.status = params.status == 1 ? 2 : 1
      const res = await updateBank(params)

      if (res.code === '2000') {
        message.success('更新成功')
        return this.searchQuery()
      }

      message.error(res.message)
    },
    handleTableChange(pageNum, pageSize) {
      this.queryParams.pageNum = pageNum
      this.queryParams.pageSize = pageSize
      this.searchQuery()
    },
    async handleEdit(row) {
      this.modalvisible = true
      this.edit = true
      this.editChannelId = row.id
      this.modalForm.status = row.status.toString()

      await this.getChannelsWithBankId(this.editChannelId)
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          channel: row.channel,
          bankName: row.bankName,
          bankCode: row.bankCode,
          bankNo: row.bankNo,
        })
      })
    },
    createRule() {
      this.modalvisible = true
    },
    async bindChanelWithBank(bankCode) {
      const channelIdList = this.modalForm.channel
      if (channelIdList.length) {
        await bindChannelByBank({ bankCode, channelIdList })
        this.modalForm.channel = []
      }
    },
    handleOk() {
      this.confirmLoading = true
      if (this.edit) {
        const fieldsValue = this.form2.getFieldsValue()

        let data = {
          ...fieldsValue,
          id: this.editChannelId,
          status: this.modalForm.status,
        }
        updateBank(data).then((res) => {
          this.confirmLoading = false
          if (res.code == '2000') {
            this.modalvisible = false
            message.success('编辑成功')
            this.bindChanelWithBank(fieldsValue.bankCode)
            return this.searchQuery()
          }
          message.error(res.msg)
        })
      } else {
        this.form2.validateFields((err, values) => {
          if (!err) {
            return
          }
        })
        const data = { ...this.form2.getFieldsValue() }
        if (!data.bankNo || !data.bankName || !data.bankCode) return (this.confirmLoading = false)
        console.log(this.modalForm.channel)
        addNewBank({
          ...data,
          status: +this.modalForm.status,
        }).then((res) => {
          this.confirmLoading = false
          if (res.code == '2000') {
            this.modalvisible = false
            message.success('保存成功')

            this.bindChanelWithBank(data.bankCode)
            this.searchQuery()
          } else {
            message.error(res.error)
          }
        })
      }
      this.edit = false
    },
    handleCancel() {
      this.modalForm.channel = []
      this.modalvisible = false
      this.edit = false
    },
    searchQuery() {
      this.loading = true
      getBankList(this.queryParams).then((res) => {
        this.dataSource = res.data.list
        this.total = res.data.page.total
        this.confirmLoading = false
        this.loading = false
      })
    },
  },
}
</script>
<style scoped lang="less">
.appContainer ::v-deep {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
/deep/.ant-form-item {
  margin-bottom: 14px;
}
</style>
