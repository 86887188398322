<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
      <a-row>
        <a-col :span="8">
          <a-form-item label="开始时间">
            <a-date-picker v-model="queryParams.beginTime" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="结束时间">
            <a-date-picker v-model="queryParams.endTime" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="类型">
            <a-select style="width: 180px" v-model="queryParams.orderType" allowClear>
              <a-select-option value="3">充值</a-select-option>
              <a-select-option value="4">提现</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
            <a-button type="primary" style="margin-left: 8px" @click="() => {queryParams.pageNum = 1; searchQuery()}"
                      icon="search">查询</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :scroll="{ x: 1000 }"
      :pagination="false"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a @click="uploadMeth(record)"> 上传 </a>
      </span>
      <span slot="userNo" slot-scope="text, record">
        {{ record.userNo }}
      </span>
      <span slot="ruleCode" slot-scope="text, record">
        {{ record.ruleCode == 1002 ? '坐扣收费' : '阶梯收费' }}
      </span>
      <span slot="orderType" slot-scope="text, record">
        <a-tooltip>
          <template slot="title"> prompt text </template>
          {{ record.orderType == 3 ? '充值' : '提现' }}
        </a-tooltip>
      </span>
      <span slot="createTime" slot-scope="text, record">
        {{ moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') }}
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="queryParams.pageNum"
        :page-size="queryParams.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
    <a-modal :visible="modalvisible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <a-form :form="form2">
        <a-form-item label="开始日期">
          <a-date-picker v-model="createBillParam.beginTime" />
        </a-form-item>
        <a-form-item label="结束日期">
          <a-date-picker v-model="createBillParam.endTime" />
        </a-form-item>
        <a-form-item label="交易类型">
          <a-select
            ref="select"
            style="width: 120px"
            v-decorator="['orderType', { rules: [{ required: true, message: '请选择业务类型!' }], initialValue: '1' }]"
          >
            <a-select-option value="3">充值</a-select-option>
            <a-select-option value="4">提现</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      :visible="uploadModalVisible"
      :confirm-loading="uploadLoading"
      @ok="handleOkupLoad"
      @cancel="uploadModalVisible = false"
    >
    </a-modal>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { checkUserRule, updateUserRule } from '@/api/useRule'
import { createBill, delBankBillInfo, downloadTemplate, matchSettle, uploadExcel } from '@/api/settle'

import { message } from 'ant-design-vue/es'
import ATip from 'ant-design-vue/es/tooltip'
import moment from 'moment'
import { getCashList } from '@/api/cash'

export default {
  name: 'UPloadBill',
  components: {
    ATip
  },
  data() {
    return {
      delvisible: false,
      mbLoading: false,
      createLoading: false,
      uploadLoading: false,
      uploadForm: {
        channel: ''
      },
      moment,
      queryParams: {
        beginTime: '',
        endTime: '',
        fileName: '',
        orderType: '',
        pageNum: 1,
        pageSize: 10
      },
      createBillParam: {
        startTime: '',
        endTime: ''
      },
      fileList: [],
      dataSource: [],
      loading: false,
      total: 0,
      columns: [
        {
          title: '商户号',
          dataIndex: 'userNo'
        },
        {
          title: '类型',
          dataIndex: 'orderType',
          scopedSlots: { customRender: 'orderType' }
        },
        {
          title: '金额',
          dataIndex: 'amount'
        },
        {
          title: '操作人',
          dataIndex: 'updater'
        },
        {
          title: '订单号',
          dataIndex: 'orderNo'
        },
        {
          title: '提交者',
          dataIndex: 'creator'
        },
        {
          title: '提交时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      edituserId: '',
      confirmLoading: false,
      modalvisible: false,
      uploadModalVisible: false,
      edit: false,
      editRuleId: '',
      modalForm: {
        ruleCode: '',
        ruleName: '',
        webRule: {}
      },
      form2: this.$form.createForm(this, { name: 'coordinated' }),
      selectRuleType: 1002,
      stepList: [{}],
      userName: localStorage.getItem('userName') || '',
      validResult: '',
      billInfoId: '',
      delrecord: {}
    }
  },
  computed: {
    ...mapGetters(['userNo', 'userId'])
  },
  created() {
  },
  methods: {
    fielRemove(file) {
      this.fileList.forEach((item, index) => {
        if (item == file) {
          this.fileList.splice(index, 1)
        }
      })
    },
    customBeforeUpload(file) {
      this.fileList.push(file)
      return false
    },
    handleOkupLoad() {
      this.uploadLoading = true
      let data = new FormData()
      data.append('billInfoId', this.billInfoId)
      this.fileList.forEach((item) => {
        data.append('files', item)
      })
      uploadExcel(data).then((res) => {
        if (res.code == 2000) {
          message.success('上传成功')
          this.fileList = []
        }
        this.searchQuery()
        this.uploadModalVisible = false
        this.uploadLoading = false
      })
    },
    uploadMeth(record) {
      this.billInfoId = record.id
      this.uploadModalVisible = true
    },
    fomartCondition(record) {
      const conditions = JSON.parse(record.conditionParam || '{}')
      const { deduction } = conditions
      // 坐扣
      if (deduction) {
        return `固定金额: ${deduction.fixedAmount}, 费率: ${deduction.rate}`
      }
    },
    onRuleCodeChange(ruleCode) {
      this.selectRuleType = ruleCode
      this.form2.setFieldsValue({
        ruleCode
      })
    },
    async onDownloadTemplate() {
      this.mbLoading = true
      downloadTemplate().then((res) => {
        this.mbLoading = false
        const elink = document.createElement('a')
        elink.download = decodeURIComponent('模板')
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(res)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      })
    },
    async onMatchSettle(record) {
      let data = new FormData()
      data.append('id', record.id)
      await matchSettle(data).then(res => {
          if (res.code == '2000') {
            message.success('对账成功')
          } else {
            message.error(res.msg)
          }

        }
      )
    },
    handleCanceldel() {
      this.delvisible = false
    },
    handleOkdel() {
      this.delvisible = false
      this.onDelBankBillInfo(this.delrecord)
    },
    openDelModal(record) {
      this.delvisible = true
      this.delrecord = record
    },
    async onDelBankBillInfo(record) {
      let data = new FormData()
      data.append('id', record.id)
      await delBankBillInfo(data).then(res => {
        if (res.code == 2000) {
          this.searchQuery()
          message.success('删除成功')
        } else {
          message.error(res.msg)
        }
      })
    },
    handleChange(value) {
      this.queryParams.ruleCode = value
    },
    handleTableChange(pageNum, pageSize) {
      this.queryParams.pageNum = pageNum
      this.queryParams.pageSize = pageSize
      this.searchQuery()
    },
    handleEdit(row) {
      const ruleCode = row.ruleCode
      this.selectRuleType = ruleCode
      const json = JSON.parse(row.conditionParam || '{}')

      if (ruleCode == 1002) {
        if (json.deduction) {
          this.$nextTick(() => {
            this.form2.setFieldsValue({
              ruleCode,
              // bizType: row.bizType,
              fixedAmount: json.deduction.fixedAmount,
              rate: json.deduction.rate
            })
          })
        }
      } else {
        if (json.stepList) {
          this.stepList = json.stepList
        }
        this.$nextTick(() => {
          this.form2.setFieldsValue({
            ruleCode
            // bizType: row.bizType,
          })
        })
      }

      this.modalvisible = true
      this.edit = true
      this.editRuleId = row.id
    },
    createRule() {
      this.modalvisible = true
    },
    async handleOk() {
      this.confirmLoading = true
      if (this.edit) {
        const fields = this.form2.getFieldsValue()
        let data = {
          userId: this.userId,
          bizType: +fields['bizType'],
          ruleCode: fields['ruleCode'],
          id: this.editRuleId,
          creator: this.userName,
          updater: this.userName
        }

        const webRule = {}
        if (this.selectRuleType == '1002') {
          webRule.webDeduction = {
            fixedAmount: +fields['fixedAmount'],
            rate: +fields['rate']
          }
        } else {
          webRule.webStepList = this.stepList
        }
        data.webRule = webRule

        console.log(data)
        updateUserRule(data)
          .then((res) => {
            this.confirmLoading = false
            if (res.code == '2000') {
              this.modalvisible = false
              this.edit = false
              message.success('编辑成功')
              this.searchQuery()
              this.handleCancel()
            }
          })
          .catch((err) => {
            this.edit = false
          })
      } else {
        this.form2.validateFields((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values)

            const fields = this.form2.getFieldsValue()
            const params = {
              startTime: moment(this.createBillParam.startTime).format('YYYY-MM-DD HH:mm:SS'),
              endTime: moment(this.createBillParam.endTime).format('YYYY-MM-DD HH:mm:SS'),
              orderType: +fields['orderType']
            }

            createBill(params)
              .then((res) => {
                this.confirmLoading = false
                if (res.code == '2000') {
                  this.modalvisible = false
                  message.success('保存成功')
                  this.searchQuery()
                  this.handleCancel()
                }
              })
              .catch((err) => {
                this.confirmLoading = false
              })
            this.edit = false
          }
        })
      }
    },
    async handleValid() {
      this.form2.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)

          const fields = this.form2.getFieldsValue()
          const ruleCode = fields['ruleCode']
          const bizType = fields['bizType']

          const webRule = {}
          if (ruleCode == '1002') {
            webRule.webDeduction = {
              fixedAmount: fields['fixedAmount'],
              rate: fields['rate']
            }
          } else {
            webRule.webStepList = this.stepList
          }

          checkUserRule({
            userId: this.userId,
            bizType,
            ruleCode,
            webRule
          })
            .then((res) => {
              this.confirmLoading = false
              if (res.code == '2000') {
                // TODO: show result
                this.validResult = res.data
              }
            })
            .catch((err) => {
              this.confirmLoading = false
            })
          this.edit = false
        }
      })
    },
    async handleCancel() {
      this.stepList = [{}]
      this.form2.setFieldsValue({
        ruleCode: '1002',
        // bizType: +row.bizType,
        // bizType: '1',
        fixedAmount: '',
        rate: ''
      })
      this.selectRuleType = 1002
      this.validResult = ''
      this.modalvisible = false
      this.edit = false
    },
    searchQuery() {
      this.loading = true
      getCashList(this.queryParams).then((res) => {
        this.dataSource = res.data.list
        this.total = res.data.page.total
        this.confirmLoading = false
        this.loading = false
      })
    }
  }
}
</script>
<style scoped lang="less">
.appContainer ::v-deep {
  .ant-form-item {
    margin-bottom: 14px;
  }
}

/deep/ .ant-form-item {
  margin-bottom: 14px;
}
</style>
