<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
      <a-row :gutter="12">
        <a-col :span="5">
          <a-form-item label="渠道名称">
            <a-select ref="selectRef" style="width: 200px" allowClear v-model="queryParams.channel">
              <a-select-option :value="item.channel" v-for="(item, key) in channels" :key="key"
                >{{ item.channel }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
            <a-button type="primary" @click="() => {queryParams.pageNum = 1; searchQuery()}" icon="search">查询</a-button>
            <a-button type="primary" @click="onReset" icon="reload" style="margin-left: 8px">重置</a-button>
            <a-button type="primary" style="margin-left: 8px" @click="createRule">新增</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :scroll="{ x: 1000 }"
      :pagination="false"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="primary" ghost @click="handleEdit(record)"> 编辑 </a-button>
        <a-button type="primary" ghost style="margin-left: 5px" slot="status" @click="onOpenOrDisable(record)">
          {{ record.status == 1 ? '禁用' : '启用' }}</a-button
        >
      </span>
      >
      <span slot="status" slot-scope="text, record">
        {{ record.status == 1 ? '启用' : record.status == 2 ? '禁用' : '无状态' }}
      </span>

      <span slot="level" slot-scope="text, record">
        <a-button @click="onSwitchChannel(record)" :disabled="(record.level == 1) || record.status == 2">
          {{ record.level == 1 ? '已启用' : '未启用' }}
        </a-button>
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="queryParams.pageNum"
        :page-size="queryParams.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
    <a-modal :visible="modalvisible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <a-form :form="form2">
        <a-form-item label="渠道名称">
          <a-input
            placeholder="渠道名称"
            v-decorator="['channel', { rules: [{ required: true, message: '请输入渠道名称!' }] }]"
          />
        </a-form-item>
        <a-form-item label="最小限额">
          <a-input
            placeholder="最小限额"
            min="0"
            type="number"
            v-decorator="['minLimitAmount', { rules: [{ required: true, message: '请输入最小限额!' }] }]"
          />
        </a-form-item>
        <a-form-item label="最大限额">
          <a-input
            placeholder="最大限额"
            min="1"
            type="number"
            v-decorator="['maxLimitAmount', { rules: [{ required: true, message: '请输入最大限额!' }] }]"
          />
        </a-form-item>
<!--        <a-form-item label="优先级">-->
<!--          <a-input-->
<!--            placeholder="优先级"-->
<!--            type="number"-->
<!--            min="0"-->
<!--            v-decorator="['level', { rules: [{ required: true, message: '请输入优先级!' }] }]"-->
<!--          />-->
<!--        </a-form-item>-->
        <a-form-item label="结算周期">
          <a-input
            placeholder="结算周期"
            min="1"
            type="number"
            v-decorator="['settleCycle', { rules: [{ required: true, message: '请输入结算周期!' }] }]"
          />
        </a-form-item>
        <a-form-item label="状态">
          <a-select ref="select" style="width: 120px" allowClear v-model="modalForm.status">
            <a-select-option value="1">启用</a-select-option>
            <a-select-option value="2">禁用</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { getChannelList, addNewChannel, updateChannel, getAllChannels, switchPayOutChannel } from '@/api/channel'
import { message } from 'ant-design-vue/es'

export default {
  name: 'ChannelList',
  data() {
    return {
      queryParams: {
        channel: '',
        pageNum: 1,
        pageSize: 10,
      },
      dataSource: [],
      channels: [],
      loading: false,
      total: 0,
      columns: [
        {
          title: '渠道名称',
          dataIndex: 'channel',
        },
        {
          title: '最小限额',
          dataIndex: 'minLimitAmount',
        },
        {
          title: '最大限额',
          dataIndex: 'maxLimitAmount',
        },
        {
          title: '放款渠道',
          dataIndex: 'level',
          scopedSlots: { customRender: 'level' },
        },
        {
          title: '结算周期',
          dataIndex: 'settleCycle',
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '更新人',
          dataIndex: 'updater',
        },
        {
          title: '创建人',
          dataIndex: 'creator',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 200,
          align: 'center',
        },
      ],
      confirmLoading: false,
      modalvisible: false,
      edit: false,
      editChannelId: '',
      modalForm: {
        status: '1',
      },
      form2: this.$form.createForm(this, { name: 'coordinated' }),
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const res = await getAllChannels()
      if (res.code === '2000') {
        this.channels = res.data
      }
    },
    onReset() {
      this.queryParam.pageNum = 1
      this.queryParam.pageSize = 10
      this.queryParams.channel = ''
      this.dataSource = []
    },
    async onSwitchChannel(record) {
      if (record.status != 1) {
        return this.$message.error('状态为启用时才可切换放款渠道')
      }

      this.$confirm({
        title: `是否切换渠道${record.channel}为放款渠道`,
        onOk: () => {
          switchPayOutChannel({ id: record.id })
            .then((res) => {
              this.searchQuery()
            })
            .catch((e) => {
              this.$message.error(e.message)
            })
        },
      })
    },
    async onOpenOrDisable(record) {
      const params = { ...record }
      params.status = params.status == 1 ? 2 : 1
      // 渠道禁用的时候放款也关闭
      if (params.status == 2) {
        params.level = 0
      }
      const res = await updateChannel(params)

      if (res.code === '2000') {
        message.success('更新成功')
        return this.searchQuery()
      }

      message.error(res.message)
    },
    handleTableChange(pageNum, pageSize) {
      this.queryParams.pageNum = pageNum
      this.queryParams.pageSize = pageSize
      this.searchQuery()
    },
    handleEdit(row) {
      this.modalvisible = true
      this.edit = true
      this.editChannelId = row.id
      this.modalForm.status = row.status.toString()
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          channel: row.channel,
          minLimitAmount: row.minLimitAmount,
          maxLimitAmount: row.maxLimitAmount,
          level: row.level,
          settleCycle: row.settleCycle,
        })
      })
    },
    createRule() {
      this.modalvisible = true
    },
    handleOk() {
      this.confirmLoading = true
      if (this.edit) {
        const fieldsValue = this.form2.getFieldsValue()

        let data = {
          ...fieldsValue,
          id: this.editChannelId,
          status: this.modalForm.status,
        }

        updateChannel(data).then((res) => {
          this.confirmLoading = false
          if (res.code == '2000') {
            this.modalvisible = false
            message.success('编辑成功')
            return this.searchQuery()
          }
          message.error(res.msg)
        })
      } else {
        this.form2.validateFields((err, values) => {
          if (!err) {
            return
          }
        })
        const data = { ...this.form2.getFieldsValue() }
        if (!data.level || !data.minLimitAmount || !data.maxLimitAmount || !data.settleCycle)
          return (this.confirmLoading = false)

        data.level = +data.level
        data.minLimitAmount = +data.minLimitAmount
        data.maxLimitAmount = +data.maxLimitAmount
        data.settleCycle = +data.settleCycle

        addNewChannel({
          ...data,
          status: +this.modalForm.status,
        }).then((res) => {
          this.confirmLoading = false
          if (res.code == '2000') {
            this.modalvisible = false
            message.success('保存成功')
            this.searchQuery()
          } else {
            message.error(res.error)
          }
        })
      }
      this.edit = false
    },
    handleCancel() {
      this.modalvisible = false
      this.edit = false
    },
    searchQuery() {
      this.loading = true
      getChannelList(this.queryParams).then((res) => {
        this.dataSource = res.data.list
        this.total = res.data.page.total
        this.confirmLoading = false
        this.loading = false
      })
    },
  },
}
</script>
<style scoped lang="less">
.appContainer ::v-deep {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
/deep/.ant-form-item {
  margin-bottom: 14px;
}
</style>
